.Image {
    margin: 35px;
    filter: invert(53%) sepia(50%) saturate(605%) hue-rotate(94deg) brightness(95%) contrast(84%);
}

.ImageBackground {
    width: 220px;
    height: 220px;
    background: linear-gradient(135deg, rgb(230, 230, 230) 0%, rgb(220, 220, 220) 35%, rgb(217, 217, 217) 100%);
    border-radius: 50%;
    margin: 0 auto;
    margin-Bottom: 15px;
}