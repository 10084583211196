.Form {
    border: 2px;
    border-color: #2FAC66;
    border-style: solid;
    background: linear-gradient(135deg, rgb(230, 230, 230) 0%, rgb(220, 220, 220) 35%, rgb(217, 217, 217) 100%);
    padding: 20px;
    border-radius: 5px;
    font-family: 'Open Sans', sans-serif;
    margin-top: 20px;
}

.Text {
    color: #575756;
    text-align: justify;
    font-family: 'Open Sans', sans-serif;
    font-size: large;
}