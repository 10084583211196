.Segment {
    width: 100%;
    height: auto;
    background: linear-gradient(135deg, rgba(105,105,105,1) 0%, rgba(82,82,82,1) 35%, rgba(75,75,75,1) 100%);
}

.SecondarySegment {
        width: 100%;
        height: auto;
        background: linear-gradient(135deg, rgba(62,204,124,1) 0%, rgba(47,172,102,1) 35%, rgba(34,122,73,1) 100%);
}

.ShortSegment {
    width: 100%;
    height: auto;
    max-height: 650px;
    background: linear-gradient(135deg, rgba(105,105,105,1) 0%, rgba(82,82,82,1) 35%, rgba(75,75,75,1) 100%);
}

.Container {
    margin-top: 75px;
    margin-bottom: 75px;
}

.HeaderContainer {
    text-align: center;
    width: 100%;
    padding-top: 10px;
}

.Header {
    font-family: 'Open Sans', sans-serif;
    color: white;
}

.Seperator {
    margin: 0 auto;
    margin-bottom: 15px;
    width: 75px;
    border-Bottom: 2px solid #2fac66;
}

.SecondarySeperator {
    margin: 0 auto;
    margin-bottom: 15px;
    width: 75px;
    border-Bottom: 2px solid #525252;
}