.NavigationItems {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: column;
    align-items: stretch;
    height: 100%;
    list-style-position: left;
    position: sticky;
    width: 100%;
}

.Button {
    background-color: Transparent;
    border: none;
}

@media (min-width: 500px) {
    .NavigationItems {
        flex-flow: row;
    }
}