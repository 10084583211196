    .NavigationItem {
        margin: 0;
        display: flex;
        height: 100%;
        width: auto;
        align-items: center;
        color: white;
        height: 100%;
        padding: 16px 10px;
        background: transparent;
        border: none;
        border-bottom: 4px solid transparent;
    }

    .NavigationItem:disabled {
        color: #525252;
    }
    
    .NavigationItem:hover:enabled,
    .NavigationItem:active:enabled {
        background-color: #3EC675;
        border-bottom: 4px solid white;
        color: white;
    }