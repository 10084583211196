.Card {
    height: 280px;
    width: 280px;
    background: linear-gradient(135deg, rgba(62,204,124,1) 0%, rgba(47,172,102,1) 35%, rgba(34,122,73,1) 100%);
    border-radius: 5px;
    margin: 0 auto;
    margin-bottom: 30px;
}

.CardHeading {
    position: relative;
    height: 70px;
    width: 280px;
    padding: 20px;
    padding-bottom: 5px;
}

.CardHeadingText {
    vertical-align: middle;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    color: #d9d9d9;
}

.CardSeperator {
    left: 50%;
    margin-left: -25px;
    position: absolute;
    bottom: 0;
    width: 50px;
    border-bottom: 2px solid #525252;
}

.CardBody {
    height: 200px;
    padding: 20px;
    padding-top: 5px;

}

.CardBodyText {
    text-align: left;
    font-family: 'Open Sans', sans-serif;
    color: #d9d9d9;
    padding: 20px;
    padding-top: 5px;

}