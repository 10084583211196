  .Facebook {
    color: #2fac66;
    padding: 0 5px;
  }

  .Facebook:hover {
    color: #3B5998;
  }

  .Twitter {
    color: #2fac66;
    padding: 0 5px;
  }

  .Twitter:hover {
    color: #4099FF;
  }

  .Instagram {
    color: #2fac66;
    padding: 0 5px;
  }

  .Instagram:hover {
    color: #833AB4;
  }

  .Youtube {
    color: #2fac66;
    padding: 0 5px;
  }

  .Youtube:hover {
    color: #FF0000;
  }

  .Linkedin {
    color: #2fac66;
    padding: 0 5px;
  }

  .Linkedin:hover {
    color: #0e76a8;
  }
  
  #lab_social_icon_footer .social:hover {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
  }
  
  #lab_social_icon_footer .social {
    -webkit-transform: scale(0.8);
    /* Browser Variations: */
    
    -moz-transform: scale(0.8);
    -o-transform: scale(0.8);
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
  }
  /*
      Multicoloured Hover Variations
  */
  
  #lab_social_icon_footer #social-fb:hover {
    color: #3B5998;
  }
  
  #lab_social_icon_footer #social-tw:hover {
    color: #4099FF;
  }
  
  #lab_social_icon_footer #social-gp:hover {
    color: #d34836;
  }
  
  #lab_social_icon_footer #social-em:hover {
    color: #f39c12;
  }