.Toolbar {
    height: 56px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: linear-gradient(135deg, rgba(62,204,124,1) 0%, rgba(47,172,102,1) 35%, rgba(34,122,73,1) 100%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    z-index: 90;
}

.Toolbar nav {
    height: 100%;
}

.Logo {
    height: 80%;
}

.AccountLogo {
    height: 80%;
    background-color: Transparent;
    border: none;
}

@media (max-width: 499px) {
    .DesktopOnly {
        display: none;
    }
}